import React from 'react'

import Landing from 'components/Landing'
import Layout from '../components/layout'
import Contact from 'components/Contact'
import BackLink from 'components/BackLink'
import Seo from '../components/seo'

const ContactPage = () => (
  <>
    <Landing title={<h1 style={{ paddingLeft: 20 }}>Contact</h1>} height={80} />
    <Layout>
      <Seo title="Contact" />
      <Contact />
      <BackLink />
    </Layout>
  </>
)

export default ContactPage
